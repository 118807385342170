<template>
  <div>
    <b-row class="mb-5">
      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <label>Date From</label>
          <b-input
            v-model="filter.date_from"
            type="date"
            @change="onFilterChange"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <label>Date To</label>
          <b-input
            v-model="filter.date_to"
            type="date"
            @change="onFilterChange"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="showFilterDistribution"
        cols="12"
        sm="6"
      >
        <b-form-group>
          <label>Filter by Distribution</label>
          <b-select
            v-model="filter.distribution"
            :options="filterDistributions"
            @change="onFilterChange"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <b-form-group>
          <label>Filter by Status</label>
          <b-select
            v-model="filter.status"
            :options="filterStatuses"
            @change="onFilterChange"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        :sm="showFilterDistribution ? 12 : 6"
      >
        <b-form-group>
          <label>Search String</label>
          <b-input
            v-model="tableSettings.filter"
            type="text"
            placeholder="Enter search string"
            debounce="1000"
          />
        </b-form-group>
      </b-col>

    </b-row>

    <b-table
      ref="table"
      hover
      responsive
      :per-page="tableSettings.perPage"
      :current-page="tableSettings.currentPage"
      :items="myProvider"
      :fields="tableSettings.fields"
      :sort-by.sync="tableSettings.sortBy"
      :sort-desc.sync="tableSettings.sortDesc"
      :sort-direction="tableSettings.sortDirection"
      :filter="tableSettings.filter"
      :filter-included-fields="tableSettings.filterOn"
      show-empty
    >
      <template #cell(index)="data">
        {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
      </template>

      <template #cell()="data">
        <div class="text-nowrap">
          {{ data.value }}
        </div>
      </template>

      <template #cell(status)="data">
        <div class="text-nowrap">
          {{ getStatus(data.item ) }}
        </div>
      </template>

      <template #cell(warehouse_pick_up)="data">
        <span :class="data.item.warehouse_pick_up == 1 ? 'text-success' :'text-danger' "> {{ data.item.warehouse_pick_up === 1 ? 'Yes' : 'No' }}</span>
      </template>

      <template #cell(is_close)="data">
        <span :class="data.item.is_close === 1 ? 'text-success' : 'text-danger'"> {{ data.item.is_close === 1 ? 'Yes' : 'No' }}</span>
      </template>

      <template #cell(actions)="row">
        <div class="text-nowrap">
          <b-button
            size="sm"
            class="mr-1"
            @click="onShowViewDetailModal(row.item)"
          >
            Show Details
          </b-button>
          <b-button
            v-if="row.item.status == 'Rejected' && (user ? user.role : '') === 'user'"
            size="sm"
            variant="success"
            @click="onShowEditModalForm(row.item)"
          >
            Edit Sales Order
          </b-button>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <div class="w-100 w-sm-25 mb-2 sm-mb-2">
          <b-select
            v-model="tableSettings.perPage"
            :options="tableSettings.pageOptions"
            size="sm"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="tableSettings.currentPage"
          :total-rows="tableSettings.totalRows"
          :per-page="tableSettings.perPage"
          first-number
          last-number
          pills
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-show-details"
      scrollable
      no-close-on-backdrop
      title="Show Details"
      size="xl"
      @ok="onConfirmCloseSo"
    >
      <b-tabs
        no-body
      >
        <b-tab
          title="Order Details"
          active
        >
          <b-row>
            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="created_at">Transaction Date</label>
                <b-input
                  id="created_at"
                  v-model="salesOrderDetails.created_at"
                  name="created_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="document_number">Document Number</label>
                <b-input
                  id="document_number"
                  v-model="salesOrderDetails.document_number"
                  name="document_number"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="customer_code">Customer Code</label>
                <b-input
                  id="customer_code"
                  v-model="salesOrderDetails.customer_code"
                  name="customer_code"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="customer_name">Customer Name</label>
                <b-input
                  id="customer_name"
                  v-model="salesOrderDetails.customer_name"
                  name="customer_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="term_name">Payment Term</label>
                <b-input
                  id="term_name"
                  v-model="salesOrderDetails.term_name"
                  name="term_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="discount_name">Discount Type</label>
                <b-input
                  id="discount_name"
                  v-model="salesOrderDetails.discount_name"
                  name="discount_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="discount_percentage">Discount %</label>
                <b-input
                  id="discount_percentage"
                  v-model="salesOrderDetails.discount_percentage"
                  name="discount_percentage"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="shipment_type">Shipment Type</label>
                <b-input
                  id="shipment_type"
                  :value="salesOrderDetails.warehouse_pick_up === 1 ? 'For Pick Up' : 'For Delivery'"
                  name="shipment_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="remarks">Remarks</label>
                <b-textarea
                  id="remarks"
                  v-model="salesOrderDetails.remarks"
                  name="remarks"
                  type="text"
                  :disabled="true"
                  rows="6"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <strong>Ordered Item(s)</strong>
                </b-col>
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    responsive
                    :items="list_items"
                    :fields="tableOrderItemSettings.fields"
                  />
                </b-col>
                <b-col
                  cols="12"
                  class="d-flex justify-content-end align-items-end"
                >
                  <b>Total Cost:&nbsp;<span>{{ numberFormatter(salesOrderDetails.total_cost) }}</span></b>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </b-tab>

        <b-tab
          v-if="product_history.data.length > 0"
          title="Transaction History"
        >
          <b-card
            v-for="(product, index) in product_history.data"
            :key="index"
            no-body
          >
            <p
              v-b-toggle="`collapse-${index}-inner`"
              class="pl-2 py-2 m-0"
              :style="`cursor:pointer;  ${product.status === 'Delivered' || product.status === 'Picked-up' ? 'background-color:#06C270;color:white' : 'background-color:#dee2e6'}`"
            >
              Transaction Date: {{ dateFormatter(product.delivered_at) }}
            </p>
            <b-collapse :id="`collapse-${index}-inner`">
              <p class="mt-2">
                Prepared By: {{ product.approved_by }}<br>
                Status: {{ product.status }}
              </p>
              <b-table
                striped
                hover
                :items="product.transaction_items"
                :fields="product_history.fields"
              >
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(product_name)="data">
                  {{ data.item.sale_order_detail.product_name }}
                </template>
              </b-table>
            </b-collapse>
          </b-card>
        </b-tab>

        <b-tab
          title="Group Approver"
        >
          <b-table
            striped
            hover
            responsive
            :items="list_approvers"
            :fields="tableApproverSettings.fields"
          >
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
          </b-table>
        </b-tab>

      </b-tabs>

      <template #modal-footer="{cancel, ok}">
        <b-button
          v-if="showCloseButton"
          variant="success"
          @click="ok()"
        >
          Close Sales Order
        </b-button>
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <SalesOrderFormComponents
      ref="soForm"
      :datax="salesOrderEditDetails"
      @onFilterChange="onFilterChange"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SalesOrderFormComponents from '@/components/salesorder/SalesOrderFormComponents.vue'
import { SharedListService, SharedSalesOrderService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'TrackerTable',

  components: {
    SalesOrderFormComponents
  },

  mixins: [formatter],

  data () {
    return {
      isBusy: false,
      list_items: [],
      list_approvers: [],
      distributions: [],
      filter: {
        distribution: 'All',
        status: this.$route.params?.status || 'All',
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      salesOrderDetails: {
        id: 0,
        created_at: '',
        document_number: '',
        customer_code: '',
        customer_name: '',
        term_name: '',
        discount_name: '',
        discount_percentage: '',
        remarks: '',
        total_cost: 0,
        is_close: 0
      },
      salesOrderEditDetails: {},
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'transaction date', sortable: true, formatter: this.dateTimeFormatter },
          { key: 'document_number.', sortable: true },
          { key: 'customer_name', sortable: true },
          { key: 'status', sortable: true },
          { key: 'group_approver', sortable: true },
          { key: 'warehouse_pick_up', label: 'Warehouse Pick Up ?', sortable: true },
          { key: 'is_close', label: 'Is Close ?', sortable: true }
          // { key: 'distribution_code', sortable: true }
        ]
      },
      tableOrderItemSettings: {
        fields: [
          { key: 'category_name' },
          { key: 'product_name' },
          { key: 'quantity' },
          { key: 'delivered_quantity' },
          { key: 'pending_deliver' },
          { key: 'price', formatter: this.numberFormatter, tdClass: 'text-right' },
          { key: 'total_price', formatter: this.numberFormatter, tdClass: 'text-right' }
        ]
      },
      tableApproverSettings: {
        fields: [
          'index',
          { key: 'approver_name' },
          { key: 'role' },
          { key: 'status' },
          { key: 'date_approved' },
          { key: 'comment' }
        ]
      },
      product_history: {
        fields: [
          'index',
          { key: 'product_name' },
          { key: 'quantity' }
        ],
        data: []
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    filterDistributions () {
      return [].concat([{ value: 'All', text: 'All' }], this.distributions)
    },

    filterStatuses () {
      let status = []

      if (this.user?.role === 'sra' || this.user?.role === 'cco' || this.user?.role === 'user' || this.user?.role === 'manager' || this.user?.role === 'tm') {
        status = [
          { text: 'All', value: 'All' },
          { text: 'Pending', value: 'Pending' },
          { text: 'Approve', value: 'Approvesracco' },
          { text: 'Partially Serve', value: 'Partially Approved' },
          { text: 'Fully Serve', value: 'Approved' },
          { text: 'Partially Delivered', value: 'Partially Delivered' },
          { text: 'Fully Delivered', value: 'Delivered' },
          { text: 'Closed Sales Order', value: 'close_so' },
          { text: 'Rejected', value: 'Rejected' }
        ]
      } else if (this.user?.role === 'warehouse') {
        status = [
          { text: 'All', value: 'All' },
          { text: 'Pending', value: 'Pending' },
          { text: 'Rejected', value: 'Rejected' },
          { text: 'Partially Serve', value: 'Partially Approved' },
          { text: 'Fully Serve', value: 'Approved' },
          { text: 'Partially Delivered', value: 'Partially Delivered' },
          { text: 'Fully Delivered', value: 'Delivered' },
          { text: 'Closed Sales Order', value: 'close_so' }
        ]
      } else {
        status = [
          { text: 'All', value: 'All' },
          { text: 'Pending', value: 'Pending' },
          { text: 'Rejected', value: 'Rejected' },
          { text: 'Partially Delivered', value: 'Partially Delivered' },
          { text: 'Fully Delivered', value: 'Delivered' }
        ]
      }

      return status
    },

    showCloseButton () {
      return !!(this.user?.role === 'sra' || this.user?.role === 'cco') && this.salesOrderDetails.is_close === 0
    },

    showFilterDistribution () {
      return this.user?.role !== 'user'
    }
  },

  mounted () {
    this.fetchDistributionList()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await SharedSalesOrderService.getPendingSalesOrder(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&tracker=1&distribution=${this.filter.distribution}&status=${this.filter.status}&date_from=${this.filter.date_from}&date_to=${this.filter.date_to}`)
        this.tableSettings.totalRows = data.total_rows
        return data.data
      } catch {
        return []
      }
    },

    async fetchDistributionList () {
      this.distributions = []
      await SharedListService.getDistributionList().then(({ data }) => {
        data.forEach(item => {
          this.distributions.push({
            value: item.id,
            text: item.distribution_name
          })
        })
      })
    },

    onConfirmCloseSo (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$swal.fire({
        icon: 'question',
        title: 'Are you sure want to close this sales order?',
        showCancelButton: true,
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Confirm',
        cancelButtonColor: '#FF2929',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          return this.closeSo()
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async closeSo () {
      return new Promise((resolve, reject) => {
        const { id } = this.salesOrderDetails
        SharedSalesOrderService.updateCloseSo({ id }).then(({ data }) => {
          this.$bvModal.hide('modal-show-details')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(() => {
        })
      })
    },

    getStatus (row) {
      let newStatus = row.status

      if (row.group_approver === 'Warehouse') {
        if (row.status === 'Approved') {
          newStatus = 'Fully Serve'
        } else if (row.status === 'Partially Approved') {
          newStatus = 'Partially Serve'
        } else {
          newStatus = row.status
        }
      } else if (row.group_approver === 'Logistics') {
        if (row.status === 'Pending') {
          newStatus = 'For Delivery'
        } else if (row.status === 'Delivered') {
          newStatus = 'Fully Delivered'
        } else {
          newStatus = row.status
        }
      }

      return newStatus
    },

    onShowEditModalForm (item) {
      if (item.status === 'Rejected') {
        this.salesOrderEditDetails = item
        this.$refs.soForm.showModal()
      } else {
        alert('Hey! Only the rejected sales order should be edited.')
      }
    },

    onShowViewDetailModal (item) {
      this.salesOrderDetails.id = item.id
      this.salesOrderDetails.created_at = this.dateTimeFormatter(item.created_at)
      this.salesOrderDetails.document_number = item.document_number
      this.salesOrderDetails.customer_code = item.customer_code
      this.salesOrderDetails.customer_name = item.customer_name
      this.salesOrderDetails.term_name = item.term_name
      this.salesOrderDetails.discount_name = item.discount_name
      this.salesOrderDetails.discount_percentage = item.discount_percentage
      this.salesOrderDetails.remarks = item.remarks
      this.salesOrderDetails.is_close = item.is_close

      const mappedItems = item.sale_order_details.map(x => {
        return {
          id: x.id,
          category_name: x.category_name,
          product_name: x.product_name,
          quantity: x.quantity,
          price: x.price,
          total_price: Number(x.quantity, 2) * Number(x.price, 2),
          quantity_out: 0,
          delivered_quantity: this.getQuantity(x.id, item, ['Delivered', 'Picked-up']),
          disabled: x.quantity_out === x.quantity,
          pending_deliver: this.getQuantity(x.id, item, ['Pending'])
        }
      })

      const sumTotalPrice = mappedItems.reduce((acc, ele) => {
        return acc + parseFloat(ele.total_price)
      }, 0)

      this.salesOrderDetails.total_cost = sumTotalPrice

      const approvers = [
        {
          approver_name: item.stage_one_name,
          role: 'Sales Receivable Accountant',
          status: item.stage_one_status,
          date_approved: item.stage_one_at,
          comment: item.stage_one_comment
        },
        {
          approver_name: item.stage_two_name,
          role: 'Credit Collection Officer',
          status: item.stage_two_status,
          date_approved: item.stage_two_at,
          comment: item.stage_two_comment
        },
        {
          approver_name: item.stage_three_name,
          role: 'Warehouse',
          status: item.stage_three_status,
          date_approved: item.stage_three_at,
          comment: item.stage_three_comment
        },
        {
          approver_name: item.stage_four_name,
          role: 'Logistics',
          status: item.stage_four_status,
          date_approved: item.stage_four_at,
          comment: item.stage_four_comment
        }
      ]

      this.product_history.data = item?.transactions || []
      this.list_items = mappedItems
      this.list_approvers = approvers

      this.$bvModal.show('modal-show-details')
    },

    getQuantity (id, item, status) {
      return item?.transactions.filter(el => {
        if (status.indexOf(el.status) !== -1) {
          return el
        }
      }).flatMap(el => {
        return el?.transaction_items.map((t, ti) => {
          if (id === t.sale_order_detail_id) {
            return t
          } else {
            return { quantity: 0 }
          }
        })
      })?.reduce((prev, current) => {
        // eslint-disable-next-line no-return-assign
        return prev += current.quantity
      }, 0)
    },

    onFilterChange () {
      this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
  .hide{
    display:none;
  }
</style>
