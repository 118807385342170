<template>
  <div>
    <b-modal
      id="modal-salesorder-form"
      scrollable
      no-close-on-backdrop
      title="Edit Sales Order"
      size="xl"
      @ok="onConfirmAction"
      @cancel="handleCancel"
      @close="handleClose"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <b-tabs
            no-body
          >
            <b-tab
              title="Order Details"
              active
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="distribution"
                    vid="distribution"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="distribution">Distribution</label>
                      <b-form-select
                        id="distribution"
                        v-model="salesorder.distribution_id"
                        :options="distributions"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isBusy"
                        @change="onDistributionChange"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Distribution --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="customer name"
                    vid="customer_name"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="customer_name">Customer Name</label>
                      <b-form-select
                        id="customer_name"
                        v-model="salesorder.customer_id"
                        :options="customers"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isBusy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Customer --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="customer code"
                    vid="customer_code"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="customer_code">Customer Code</label>
                      <b-input
                        id="customer_code"
                        v-model="salesorder.customer_code"
                        :state="errors.length > 0 ? false : null"
                        :disabled="true"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="term"
                    vid="term"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="term">Terms</label>
                      <b-form-select
                        id="term"
                        v-model="salesorder.term_id"
                        :options="terms"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isBusy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Term --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="discount"
                    vid="discount"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="discount">Discount</label>
                      <b-form-select
                        id="discount"
                        v-model="salesorder.discount_id"
                        :options="discounts"
                        :state="errors.length > 0 ? false : null"
                        :disabled="isBusy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Discount --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="discount value"
                    vid="discount_value"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="discount_value">Discount Value</label>
                      <b-input
                        id="discount_value"
                        v-model="salesorder.discount_percentage"
                        type="number"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter discount value"
                        autocomplete="off"
                        :disabled="isBusy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab title="Order Items">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="form-group">
                    <label for="product_category">Product Category</label>
                    <v-select
                      id="product_category"
                      v-model="selectedProductCategory"
                      :options="productCategories"
                      label="category_name"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="form-group">
                    <label for="product">Product</label>
                    <v-select
                      id="product"
                      v-model="selectedProduct"
                      :options="products"
                      label="product_name"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="form-group">
                    <label for="qty">Quantity</label>
                    <b-input
                      id="qty"
                      v-model="orderItemForm.qty"
                      type="number"
                      placeholder="enter quantity"
                      autocomplete="off"
                    />
                  </div>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <div class="form-group">
                    <label for="price">Price</label>
                    <b-input
                      id="price"
                      v-model="orderItemForm.price"
                      type="number"
                      placeholder="enter price"
                      autocomplete="off"
                    />
                  </div>
                </b-col>

                <b-col cols="12">
                  <b-button
                    type="button"
                    variant="success"
                    :disabled="addItemDisabled"
                    @click="onAddItem"
                  >
                    Add Item
                  </b-button>
                </b-col>

              </b-row>

              <div>
                <b-table
                  class="mt-2"
                  striped
                  hover
                  :items="items"
                  :fields="fields"
                >
                  <template #cell(action)="row">
                    <b-button
                      size="sm"
                      @click="removeSaleorderItem(row.item)"
                    >
                      remove
                    </b-button>
                  </template>

                  <template #cell(total_price)="data">
                    {{ numberFormatter(data.item.quantity * data.item.price) }}
                  </template>
                </b-table>
                <hr>
                <b-col
                  class="col-md-12 d-flex align-items-end justify-content-end"
                >
                  <h5>
                    Total Costs: <span>{{ numberFormatter(totalCost) }}</span>
                  </h5>
                </b-col>
              </div>
            </b-tab>

            <b-tab
              title="Remarks"
              @click="showSignature"
            >
              <b-row>
                <b-col cols="12">
                  <ValidationProvider
                    #default="{ errors }"
                    name="remarks"
                    vid="remarks"
                    rules="required"
                  >
                    <div class="form-group">
                      <label for="remarks">Remarks</label>
                      <b-textarea
                        id="remarks"
                        v-model="salesorder.remarks"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter remarks"
                        autocomplete="off"
                        rows="6"
                        max-rows="6"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </div>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <VPerfectSignature
                    v-if="modalShow"
                    id="signature"
                    ref="signaturePad"
                    width="100%"
                    height="250px"
                  />
                </b-col>

                <b-col cols="12">
                  <b-button
                    variant="danger"
                    @click="onClearSignature"
                  >
                    Clear Signature
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="isBusy || items.length < 1"
          @click="ok()"
        >
          Update Sales Order
        </b-button>

        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VPerfectSignature from 'v-perfect-signature'
import { SharedListService, SharedSalesOrderService, AxiosError } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SalesOrderFormComponents',

  components: {
    VPerfectSignature
  },

  mixins: [formatter],

  middleware: ['auth', 'user'],

  props: {
    datax: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      isBusy: false,
      modalShow: false,
      selectedProduct: null,
      selectedProductCategory: null,
      distributions: [],
      customers: [],
      terms: [],
      discounts: [],
      productCategories: [],
      products: [],
      items: [],
      totalCost: 0,
      strokeOptions: {
        size: 16,
        thinning: 0.75,
        smoothing: 0.5,
        streamline: 0.5
      },
      orderItemForm: {
        qty: 0,
        price: 0
      },
      salesorder: {
        id: '',
        active: 0,
        distribution_id: null,
        customer_id: null,
        customer_code: '',
        document_number: '',
        term_id: null,
        discount_id: null,
        discount_percentage: 0,
        remarks: ''
      },
      fields: [
        {
          key: 'category_name',
          sortable: true
        },
        {
          key: 'product_name',
          sortable: true
        },
        {
          key: 'quantity',
          sortable: true
        },
        {
          key: 'price',
          sortable: true,
          formatter: this.numberFormatter
        },
        {
          key: 'total_price',
          sortable: true,
          formatter: this.numberFormatter
        },
        'action'
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    addItemDisabled () {
      return !(this.selectedProduct !== null && this.selectedProductCategory !== null && parseInt(this.orderItemForm.qty) > 0 && parseFloat(this.orderItemForm.price) > 0)
    }
  },

  watch: {
    'salesorder.customer_id' (value) {
      if (value) {
        this.salesorder.customer_code = value
      }
    },

    'selectedProductCategory' (value) {
      if (value) {
        this.fetchProductList(value.id)
      }
    },

    items () {
      this.totalCost = this.items?.reduce((a, b) => {
        const totalAmount = a + b.quantity * b.price
        return totalAmount
      }, 0)
    },

    'datax' (value) {
      this.setEditFormData(value)
    }
  },

  mounted () {
    this.fetchDistributionList()
    this.fetchTermList()
    this.fetchDiscountList()
    this.fetchProductCategoryList()
  },

  methods: {
    async fetchDistributionList () {
      this.distributions = []
      await SharedListService.getDistributionList().then(
        ({ data }) => {
          data.forEach(item => {
            this.distributions.push({
              value: item.id,
              text: item.distribution_name
            })
          })
        }
      )
    },

    async fetchCustomerList (distribution) {
      this.customers = []
      await SharedListService.getCustomerList(distribution).then(({ data }) => {
        data.forEach(item => {
          this.customers.push({
            value: item.customer_code,
            text: item.customer_name
          })
        })
      }
      )
    },

    async fetchTermList () {
      this.terms = []
      await SharedListService.getTermList().then(({ data }) => {
        data.forEach(item => {
          this.terms.push({
            value: item.id,
            text: item.term_name
          })
        })
      })
    },

    async fetchDiscountList () {
      this.discounts = []
      await SharedListService.getDiscountList().then(({ data }) => {
        data.forEach(item => {
          this.discounts.push({
            value: item.id,
            text: item.discount_name
          })
        })
      })
    },

    async fetchProductCategoryList () {
      this.productCategories = []
      await SharedListService.getProductCategoryList().then(({ data }) => {
        this.productCategories = data
      })
    },

    async fetchProductList (category) {
      this.products = []
      await SharedListService.getProductList(category).then(({ data }) => {
        this.products = data
      })
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.form.validate().then(async success => {
        if (success) {
          if (this.toDataURL()) {
            this.$swal.fire({
              icon: 'question',
              title: 'Are you sure want to edit this sales order ?',
              showCancelButton: true,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Update',
              cancelButtonColor: '#FF2929',
              showLoaderOnConfirm: true,
              preConfirm: async () => {
                return this.onSubmitSalesOrder()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Error in saving data, signature is required.', {
              title: 'Validation Error',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger'
            })
          }
        } else {
          this.$bvModal.msgBoxOk('Error in saving data. make sure that all fields are filled.', {
            title: 'Validation Error',
            size: 'md',
            buttonSize: 'sm',
            okVariant: 'danger'
          })
        }
      })
    },

    handleCancel () {
      this.modalShow = false
    },

    handleClose () {
      this.modalShow = false
    },

    showSignature () {
      this.modalShow = true
    },

    onAddItem () {
      const isExisting = this.items?.filter(
        el => el.product_code === this.selectedProduct.product_code
      )
      const x = -0
      if (this.orderItemForm.qty <= x || this.orderItemForm.price < x) {
        alert(
          'please check quantity or price inputs not accepting negative value'
        )
      } else {
        if (isExisting.length) {
          const newData = this.items.map(el => {
            if (
              el.product_code === this.selectedProduct.product_code
            ) {
              el.quantity = Number(el.quantity) + Number(this.orderItemForm.qty)
              return el
            }

            return el
          })

          this.items = newData
        } else {
          this.items.push({
            category_code: this.selectedProductCategory.category_code,
            category_name: this.selectedProductCategory.category_name,
            product_code: this.selectedProduct.product_code,
            product_name: this.selectedProduct.product_name,
            quantity: this.orderItemForm.qty,
            price: this.orderItemForm.price
          })
        }
      }

      // reset quantity & price
      this.orderItemForm.qty = 1
      this.orderItemForm.price = 0
    },

    removeSaleorderItem (item) {
      const oldItem = this.items

      const newItem = oldItem.filter(el => {
        return el.product_code !== item.product_code
      })

      this.items = newItem
    },

    onClearSignature (event) {
      event.preventDefault()
      this.$refs.signaturePad.clear()
    },

    async setEditFormData (value) {
      const distributionId = value?.distribution_id || null

      if (distributionId) {
        this.customers = []
        await SharedListService.getCustomerList(distributionId).then(({ data }) => {
          data.forEach(item => {
            this.customers.push({
              value: item.customer_code,
              text: item.customer_name
            })
          })

          this.salesorder.id = value.id
          this.salesorder.distribution_id = value.distribution_id
          this.salesorder.customer_id = value.customer_code
          this.salesorder.document_number = value.document_number
          this.salesorder.term_id = value.term_id
          this.salesorder.discount_id = value.discount_id
          this.salesorder.discount_percentage = value.discount_percentage
          this.salesorder.remarks = value.remarks
          this.items = value.sale_order_details
        })
      }
    },

    async onSubmitSalesOrder () {
      return new Promise((resolve, reject) => {
        const obj = {
          id: this.salesorder.id,
          distribution_id: this.salesorder.distribution_id,
          document_number: this.salesorder.document_number,
          customer_code: this.salesorder.customer_id,
          term_id: this.salesorder.term_id,
          discount_id: this.salesorder.discount_id,
          discount_percentage: this.salesorder.discount_percentage,
          remarks: this.salesorder.remarks,
          sale_order_details: this.items,
          signature: this.toDataURL(),
          is_edit: 1
        }

        this.isBusy = true
        SharedSalesOrderService.post(obj)
          .then(response => {
            this.isBusy = false
            this.$bvModal.hide('modal-salesorder-form')
            this.$swal.fire({
              icon: 'info',
              title: 'Info',
              text: response.data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$emit('onFilterChange')
              this.modalShow = false
            })
          })
          .catch(error => {
            this.isBusy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    toDataURL () {
      return this.$refs.signaturePad?.toDataURL() || null
    },

    onDistributionChange (value) {
      if (value) {
        this.fetchCustomerList(value)
      }
    },

    showModal () {
      this.selectedProduct = null
      this.selectedProductCategory = null
      this.orderItemForm.qty = 1
      this.orderItemForm.price = 0
      this.$bvModal.show('modal-salesorder-form')
    }
  }
}
</script>

<style>
  #signature {
    border: double 3px transparent;
    border-radius: 5px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left, #4bc5e8, #9f6274);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
</style>
